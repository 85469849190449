import React from "react";
import './Input.module.scss'

type InputProps = {
    register: any,
    label: any,
    required: any,
    name: string,
    id: string,
    type?: string,
}

const Input = ({ register, label, required, name, id, type, }: InputProps) => <input {...register(label, { required })} type={type} name={name} id={id} />

export default Input;
