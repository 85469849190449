import React from "react";
import Label from 'src/components/common/Label/Label'
import Input from 'src/components/common/Input/Input'
import * as styles from './FormField.module.scss'

type FormFieldProps = {
    register: any,
    label: any,
    required: any,
    labelValue: string,
    name: string,
    id: string,
    type: string
}

const FormField = ({ register, label, required, labelValue, name, id, type, }: FormFieldProps) => {
    return (
        <div className={styles.FormField}>
            <Label labelValue={labelValue} id={id} />
            <Input register={register} label={label} required={required} name={name} id={id} type={type} />
        </div>
    )
}

export default FormField;
