import React from "react";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import clsx from "clsx";
import * as styles from "./Footer.module.scss";
import { GatsbyImage } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";

export default function Footer({ className }: any) {
  return (
    <footer className={clsx(styles.Wrapper, className && styles.noRound)}>
      <div className={styles.SiteMap}>
        <div className={styles.Grid}>
          <div className={styles.LogoWrapper}>
            <StaticImage src="../../../images/hexarlogo.png" alt="hexar logo" className={styles.GatsbyImageWrapper} />
          </div>
          <div>REGON: 368624511</div>
          <div>NIP: 1182156865</div>
          <div>MAIL: hello@hexar.tech</div>
        </div>
        <div className={styles.InfoWrapper}>
          <div className={styles.Grid}>
            <div>
              <h3>Hexar</h3>
            </div>
            <Link to="/blog">Blog</Link>
            <Link to="/use-cases">Zastosowanie</Link>
            <Link to="/privacy-policy">Polityka prywatności</Link>
          </div>
          <div className={styles.Grid}>
            <div>
              <h3>Nasz produkt</h3>
            </div>
            <Link to="/product/onboarding-offboarding" >Onboarding i Offboarding</Link>
            <Link to="/product/communication">Platforma komunikacyjna</Link>
            <Link to="/product/organizer">Firmowy organizer</Link>
            <Link to="/product/employer-branding">Employer Branding</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
