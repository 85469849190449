import React from "react";
import Button from "src/components/common/Button/Button";
import FormField from 'src/components/layout/ContactFooter/FormField/FormField'
import clsx from 'clsx';
import * as styles from './Form.module.scss'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useState } from "react";
import { Link } from 'gatsby';

const Form = ({ className }: any) => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [success, setSuccess] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = async (data: any) => {
        const response = await axios.post(
            'https://hexar-contact-request.azurewebsites.net/api/contactrequest',
            data)
        if (response) {
            if (response.status == 200) {
                setSuccess("Dziękujemy za kontakt!")
                reset();
                setTimeout(() =>
                    setSuccess("")
                    , 3000);

            } else {
                setErrorMessage("Coś poszło nie tak, spróbuj jeszcze raz.");
                setTimeout(() =>
                    setErrorMessage("")
                    , 3000);
            }
        }
    }

    return (
        <div className={clsx(styles.FormWrapper, className && styles.notAbsolute)}>
            <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
                {(success && !errorMessage) && <p style={{ color: "green" }}>{success}</p>}
                {(!success && errorMessage) && <span style={{ color: "red" }}>{errorMessage}</span>}
                <FormField register={register} required label="name" name="name" labelValue="Imię*" id="nameInput" type="text" />
                {errors.name?.type === 'required' && <span style={{ color: "red" }}>Wpisz imię</span>}
                <FormField register={register} required label="company" name="company" labelValue="Firma*" id="companyInput" type="text" />
                {errors.company?.type === 'required' && <span style={{ color: "red" }}>Wpisz nazwę firmy</span>}
                <FormField register={register} required label="email" name="email" labelValue="Mail*" id="email" type="email" />
                {errors.email?.type === 'required' && <span style={{ color: "red" }}>Wpisz adres e-mail</span>}
                <Button type="submit">Porozmawiajmy</Button>
            </form>
            <p className={styles.Privacy}>Pisząc do nas akcetpujesz naszą
                <span><Link to="/privacy-policy">Politykę Prywatności</Link></span></p>
        </div>
    )
}

export default Form;
