import React from "react";
import './Label.module.scss'

type LabelProps = {
    labelValue: string,
    id: string,
}

const Label = ({ labelValue, id }: LabelProps) => <label htmlFor={id}>{labelValue}</label>


export default Label;
