// extracted by mini-css-extract-plugin
export var MaxWidthContainer = "Footer-module--MaxWidthContainer--9oSUl";
export var SiteMap = "Footer-module--SiteMap--GofyU";
export var GrantsContent = "Footer-module--GrantsContent--uPqrk";
export var CommonPaddings = "Footer-module--CommonPaddings--F18Xy";
export var Wrapper = "Footer-module--Wrapper--+3PB9";
export var noRound = "Footer-module--noRound--nc1AJ";
export var InfoWrapper = "Footer-module--InfoWrapper--PkLJd";
export var Grid = "Footer-module--Grid--Ks0JG";
export var LogoWrapper = "Footer-module--LogoWrapper--griWO";
export var GatsbyImageWrapper = "Footer-module--GatsbyImageWrapper---9vln";
export var Grants = "Footer-module--Grants--GOSlj";