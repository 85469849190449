import React from "react";
import Form from "src/components/layout/ContactFooter/Form/Form"
import Footer from 'src/components/layout/footer/Footer'
import * as styles from './ContactFooter.module.scss'
import useWindowSize from "src/components/hooks/useWindowSize";

const ContactFooter = ({ showForm, showInfo, className }: any) => {
    const { width }: any = useWindowSize();

    return (
        <div className={styles.ContactFooter}>
            {showForm &&
                <>

                    {showInfo ?
                        <div className={styles.headerWrapper}>
                            <h2>Pierwszy miesiąc <span className={styles.yellowSpan}>bez opłat</span></h2>
                            <p>Napisz do nas i dowiedz się jak z niego skorzystać.</p>
                        </div>
                        :
                        null}
                    <Form />
                </>
            }
            <Footer className={className} />
        </div>
    )

}

export default ContactFooter;
