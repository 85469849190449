import React from "react";
import clsx from "clsx";
import { default as ChatIcon } from 'src/assets/Iconly/Bold/Chat.svg';
import { default as ArrowIcon } from 'src/assets/Iconly/Bold/Arrow.svg';

import * as styles from "./Button.module.scss";


export default function Button({
  as = "button",
  children,
  className = "",
  fullWidth = false,
  type
}: any) {
  const Component = as;
  return (
    <Component
      className={clsx(styles.Button, className)}
      style={fullWidth ? { width: "100%" } : {}}
      type={type}
    >
      {children}
      {children == "Porozmawiajmy" && <div className={styles.IconWrapper}> <ChatIcon className={styles.Icon} /></div>}
      {children == "Zobacz więcej" && <div className={styles.IconWrapper}><ArrowIcon className={styles.Icon} /></div>}
    </Component >
  );
}
